import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { Button, Card, CardBody, Col, Container, Input, Label, Row, Table } from 'reactstrap';
import { getFirmBankAccountsByUserId, getUserDataFromAuth } from "../../../slices/thunks";
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Select from "react-select";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { sendNotify, useHandleError } from "../../../helpers/utils";
import * as XLSX from 'xlsx';

const Bakiyeler = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const handleError = useHandleError();
    document.title = "Bakiyeler | Tanasoft";

    const companyData = JSON.parse(localStorage.getItem("companyData") || "{}");
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [selectedItems, setSelectedItems] = useState([]);
    const [firmAccounts, setFirmAccounts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [filteredFirmAccounts, setFilteredFirmAccounts] = useState([]);
    const totalPages = filteredFirmAccounts.length > 0 && itemsPerPage > 0 ? Math.ceil(filteredFirmAccounts.length / itemsPerPage) : 1;
    const displayedFirmAccounts = filteredFirmAccounts.slice((currentPage - 1) * (itemsPerPage > 0 ? itemsPerPage : 1), currentPage * (itemsPerPage > 0 ? itemsPerPage : 1));
    const [bank, setBank] = useState(null);
    const [bankBranch, setBankBranch] = useState(null);
    const [accountNumber, setAccountNumber] = useState("");
    const [ibanNumber, setIBANNumber] = useState("");
    const [description, setDescription] = useState("");
    const [currencyType, setCurrencyType] = useState(null);
    const [accountType, setAccountType] = useState(null);
    const [accountActive, setAccountActive] = useState(null);
    const bankSelectOptions = [...new Set(firmAccounts.map(firmAccount => firmAccount.bankName).filter(name => name))].map(name => ({ value: name, label: name }));
    const bankBranchSelectOptions = [...new Set(firmAccounts.map(firmAccount => firmAccount.branchName).filter(name => name))].map(name => ({ value: name, label: name }));
    const accountTypeSelectOptions = [...new Set(firmAccounts.map(firmAccount => firmAccount.bankAccountType).filter(name => name))].map(name => ({ value: name, label: name }));
    const currencyTypeSelectOptions = [...new Set(firmAccounts.map(firmAccount => firmAccount.currencyType).filter(name => name))].map(name => ({ value: name, label: name }));
    const accountActiveSelectOptions = [...new Set(firmAccounts.map(firmAccount => firmAccount.inUse).filter(name => name !== null && name !== undefined))].map(name => ({ value: name, label: name === true ? "Evet" : "Hayır" }));

    const handleChangePage = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const getFirmAccounts = async () => {
        dispatch(getUserDataFromAuth()).then((authResponse) => {
            const userId = authResponse.id;
            dispatch(getFirmBankAccountsByUserId(userId, true)).then((response) => {
                const firmBankAccounts = response.filter(account => account.firmId === companyData.companyID);
                setFirmAccounts(firmBankAccounts);
                setFilteredFirmAccounts(firmBankAccounts);
            }).catch((err) => {
                handleError(err);
            });
        }).catch((err) => {
            handleError(err);
        });
    };

    useEffect(() => {
        getFirmAccounts();
    }, []);

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            const allIds = displayedFirmAccounts.map((_, index) => (currentPage - 1) * itemsPerPage + index);
            setSelectedItems(allIds);
        } else {
            setSelectedItems([]);
        }
    };

    const handleSelectItem = (index) => {
        if (selectedItems.includes(index)) {
            setSelectedItems(selectedItems.filter((item) => item !== index));
        } else {
            setSelectedItems([...selectedItems, index]);
        }
    };

    const formatCurrency = (value, decimals = 2) => {
        return value.toLocaleString('tr-TR', { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
    };

    const onChangeBank = (selectedOption) => {
        setBank(selectedOption ? selectedOption.value : '');
    };

    const onChangeBankBranch = (selectedOption) => {
        setBankBranch(selectedOption ? selectedOption.value : '');
    };

    const onChangeAccountType = (selectedOption) => {
        setAccountType(selectedOption ? selectedOption.value : '');
    };

    const onChangeCurrencyType = (selectedOption) => {
        setCurrencyType(selectedOption ? selectedOption.value : '');
    };

    const onChangeAccountActive = (selectedOption) => {
        setAccountActive(selectedOption ? selectedOption.value : '');
    };

    const handleRefresh = () => {
        getFirmAccounts();
    };

    const handleExportToExcel = () => {
        let accountsToExport = displayedFirmAccounts;

        if (selectedItems.length > 0) {
            accountsToExport = firmAccounts.filter((_, index) =>
                selectedItems.includes(index)
            );
        } else {
            accountsToExport = firmAccounts;
        }

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(accountsToExport.map(account => ({
            "Banka": account.bankName || t("Bakiyeler_Belirtilmemis"),
            "Şube": account.branchName || t("Bakiyeler_Belirtilmemis"),
            "Hesap No": account.accountNumber || t("Bakiyeler_Belirtilmemis"),
            "IBAN": account.iban || t("Bakiyeler_Belirtilmemis"),
            "Hesap Türü": account.accountType || t("Bakiyeler_Belirtilmemis"),
            "Para Birimi": account.currencyType || t("Bakiyeler_Belirtilmemis"),
            "Bakiye": `${formatCurrency(account.balance)} ${account.currencyType}`,
            "Aktif mi?": account.isActive !== undefined ? (account.isActive ? t("Bakiyeler_Evet") : t("Bakiyeler_Hayir")) : t("Bakiyeler_Belirtilmemis")
        })));

        XLSX.utils.book_append_sheet(workbook, worksheet, "Bakiyeler");
        XLSX.writeFile(workbook, t("Bakiyeler_ExcelFileName") + ".xlsx");
    };

    useEffect(() => {
        const filters = {
            bank: (account) => !bank || account.bankName === bank,
            bankBranch: (account) => !bankBranch || account.branchName === bankBranch,
            accountType: (account) => !accountType || account.bankAccountType === accountType,
            currencyType: (account) => !currencyType || account.currencyType === currencyType,
            accountActive: (account) => !accountActive || account.inUse === accountActive,
            ibanNumber: (account) => !ibanNumber || (account.iban && account.iban.toLocaleLowerCase('tr-TR').includes(ibanNumber.toLocaleLowerCase('tr-TR'))),
            description: (account) => !description || (account.description && account.description.toLocaleLowerCase('tr-TR').includes(description.toLocaleLowerCase('tr-TR'))),
            accountNumber: (account) => !accountNumber || (account.accountNo && account.accountNo.toLocaleLowerCase('tr-TR').includes(accountNumber.toLocaleLowerCase('tr-TR')))
        };

        const filteredAccounts = firmAccounts.filter(account =>
            Object.values(filters).every(filterFn => filterFn(account))
        );

        setFilteredFirmAccounts(filteredAccounts);
        setCurrentPage(1);
    }, [bank, bankBranch, accountType, currencyType, accountActive, ibanNumber, description, accountNumber, firmAccounts]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t("Bakiyeler_Bakiyeler")} pageTitle={t("Bakiyeler_Bakiye")} pageLink="online-banka" />
                    <Col xs={12}>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <div style={{ marginTop: 20, marginBottom: 20, marginLeft: 25, marginRight: 25 }}>
                                        <Row>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="bank" className="form-label text-capitalize">
                                                        {t("Bakiyeler_Banka")}
                                                    </Label>
                                                    <Select
                                                        className="text-capitalize"
                                                        value={bankSelectOptions.find(option => option.value === bank)}
                                                        isClearable
                                                        placeholder={t("Bakiyeler_BankaSecin")}
                                                        onChange={onChangeBank}
                                                        options={bankSelectOptions}
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <Label htmlFor="bankAccount" className="form-label text-capitalize">
                                                        {t("Bakiyeler_BankaSube")}
                                                    </Label>
                                                    <Select
                                                        className="text-capitalize"
                                                        value={bankBranchSelectOptions.find(option => option.value === bankBranch)}
                                                        isClearable
                                                        placeholder={t("Bakiyeler_BankaSubeSecin")}
                                                        onChange={onChangeBankBranch}
                                                        options={bankBranchSelectOptions}
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <Label htmlFor="accountNumber" className="form-label text-capitalize">
                                                        {t("Bakiyeler_HesapNumarasi")}
                                                    </Label>
                                                    <Input
                                                        type="number"
                                                        className="form-control text-capitalize"
                                                        id="accountNumber"
                                                        placeholder={t("Bakiyeler_HesapNumarasiSecin")}
                                                        value={accountNumber}
                                                        onChange={(e) => setAccountNumber(e.target.value)}
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <Label htmlFor="ibanNumber" className="form-label text-capitalize">
                                                        {t("Bakiyeler_IBAN")}
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control text-capitalize"
                                                        id="ibanNumber"
                                                        placeholder={t("Bakiyeler_IBAN")}
                                                        value={ibanNumber}
                                                        onChange={(e) => setIBANNumber(e.target.value)}
                                                    />
                                                </div>
                                            </Col>

                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="currencyType" className="form-label text-capitalize">
                                                        {t("Bakiyeler_ParaBirimi")}
                                                    </Label>
                                                    <Select
                                                        className="text-capitalize"
                                                        value={currencyTypeSelectOptions.find(option => option.value === currencyType)}
                                                        isClearable
                                                        placeholder={t("Bakiyeler_ParaBirimiSecin")}
                                                        onChange={onChangeCurrencyType}
                                                        options={currencyTypeSelectOptions}
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <Label htmlFor="accountType" className="form-label text-capitalize">
                                                        {t("Bakiyeler_HesapTuru")}
                                                    </Label>
                                                    <Select
                                                        className="text-capitalize"
                                                        value={accountTypeSelectOptions.find(option => option.value === accountType)}
                                                        isClearable
                                                        placeholder={t("Bakiyeler_HesapTuruSecin")}
                                                        onChange={onChangeAccountType}
                                                        options={accountTypeSelectOptions}
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <Label htmlFor="accountActive" className="form-label text-capitalize">
                                                        {t("Bakiyeler_AktifMi")}
                                                    </Label>
                                                    <Select
                                                        className="text-capitalize"
                                                        value={accountActiveSelectOptions.find(option => option.value === accountActive)}
                                                        isClearable
                                                        placeholder={t("Bakiyeler_AktiflikDurumuSecin")}
                                                        onChange={onChangeAccountActive}
                                                        options={accountActiveSelectOptions}
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <Label htmlFor="description" className="form-label text-capitalize">
                                                        {t("Bakiyeler_Aciklama")}
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control text-capitalize"
                                                        id="description"
                                                        placeholder={t("Bakiyeler_Aciklama")}
                                                        value={description}
                                                        onChange={(e) => setDescription(e.target.value)}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <div>
                                                <Input
                                                    type="number"
                                                    placeholder={t("Bakiyeler_SayfaBasinaKayitSayisi")}
                                                    className="form-control"
                                                    style={{ width: '200px' }}
                                                    value={itemsPerPage}
                                                    onChange={(e) => {
                                                        const newValue = e.target.value.replace(/^0+/, '');
                                                        if (newValue === '') {
                                                            setItemsPerPage('');
                                                        } else {
                                                            const parsedValue = parseInt(newValue, 10);
                                                            if (!isNaN(parsedValue) && parsedValue > 0) {
                                                                setItemsPerPage(parsedValue);
                                                                setCurrentPage(1);
                                                            } else {
                                                                setItemsPerPage(1);
                                                                setCurrentPage(1);
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <Button color="success" onClick={handleExportToExcel} className="me-2">
                                                    {t("Bakiyeler_ExcelEAktar")}
                                                </Button>
                                                <Button color="primary" onClick={handleRefresh}>
                                                    {t("Bakiyeler_Yenile")}
                                                </Button>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <Table className="table align-middle table-nowrap table-hover">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: "50px" }}>
                                                            <div className="form-check">
                                                                <Input className="form-check-input" type="checkbox" id="checkAll" value="option" onChange={handleSelectAll} checked={selectedItems.length === displayedFirmAccounts.length} />
                                                            </div>
                                                        </th>
                                                        <th className="text-capitalize">{t("Bakiyeler_Banka")}</th>
                                                        <th className="text-capitalize">{t("Bakiyeler_BankaSube")}</th>
                                                        <th className="text-capitalize">{t("Bakiyeler_SubeKodu")}</th>
                                                        <th className="text-capitalize">{t("Bakiyeler_HesapNumarasi")}</th>
                                                        <th className="text-capitalize">{t("Bakiyeler_IBAN")}</th>
                                                        <th className="text-capitalize">{t("Bakiyeler_Aciklama")}</th>
                                                        {/* <th className="text-capitalize">{t("Bakiyeler_ParaBirimi")}</th> */}
                                                        <th className="text-capitalize text-center">{t("Bakiyeler_Bakiye")}</th>
                                                        <th className='text-capitalize text-center'>{t("Bakiyeler_BlokeBakiye")}</th>
                                                        <th className="text-capitalize">{t("Bakiyeler_HesapTuru")}</th>
                                                        <th className="text-capitalize">{t("Bakiyeler_AktifMi")}</th>
                                                    </tr>
                                                </thead>
                                                {displayedFirmAccounts.length >= 1 && (
                                                    <tbody>
                                                        {displayedFirmAccounts.map((firmAccount, index) => {
                                                            const globalIndex = (currentPage - 1) * itemsPerPage + index;
                                                            const isChecked = selectedItems.includes(globalIndex);

                                                            return (
                                                                <tr key={index} className={isChecked ? "table-active" : ""}>
                                                                    <td>
                                                                        <div className="form-check">
                                                                            <Input className="form-check-input" type="checkbox" name="chk_child" value="option1" checked={isChecked} onChange={() => handleSelectItem(globalIndex)} />
                                                                        </div>
                                                                    </td>
                                                                    <td>{firmAccount.bankName || t("Bakiyeler_Belirtilmemis")}</td>
                                                                    <td>{firmAccount.branchName || t("Bakiyeler_Belirtilmemis")}</td>
                                                                    <td>{firmAccount.branchCode || t("Bakiyeler_Belirtilmemis")}</td>
                                                                    <td>{firmAccount.accountNo || t("Bakiyeler_Belirtilmemis")}</td>
                                                                    <td>{firmAccount.iban || t("Bakiyeler_Belirtilmemis")}</td>
                                                                    <td>{firmAccount.description || t("Bakiyeler_Belirtilmemis")}</td>
                                                                    {/* <td>{firmAccount.currencyType || t("Bakiyeler_Belirtilmemis")}</td> */}
                                                                    <td style={{ color: 'green', textAlign: 'right' }}>{formatCurrency(firmAccount.balance)} {firmAccount.currencyType}</td>
                                                                    <td style={{ color: 'red', textAlign: 'right' }}>{formatCurrency(firmAccount.blockedBalance)} {firmAccount.currencyType}</td>
                                                                    <td>{firmAccount.bankAccountType || t("Bakiyeler_Belirtilmemis")}</td>
                                                                    <td>
                                                                        <div className={`status-badge bg-gradient ${firmAccount.inUse === true ? "bg-success" : "bg-danger"}`}>
                                                                            {firmAccount.inUse ? t("Bakiyeler_Evet") : t("Bakiyeler_Hayir")}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                )}
                                            </Table>

                                            {displayedFirmAccounts.length <= 0 && (
                                                <Row>
                                                    <div style={{ width: "100%", height: 45 }} className="d-flex justify-content-center align-items-center border-2 border-bottom">
                                                        {t("Bakiyeler_Nodata")}
                                                    </div>
                                                </Row>
                                            )}
                                        </div>

                                        <div className="d-flex justify-content-between mt-3">
                                            <div className="text-muted">
                                                {/* {((currentPage - 1) * itemsPerPage) + 1} ile{" "}
                                                {Math.min(currentPage * itemsPerPage, firmAccounts.length)} arasındaki kayıtlar gösteriliyor */}
                                                {t("SelectCompanyPopup_Records")} {((currentPage - 1) * itemsPerPage) + 1} - {Math.min(currentPage * itemsPerPage, firmAccounts.length)} {t("SelectCompanyPopup_Of")} ({firmAccounts.length})
                                            </div>
                                            <div>
                                                <ul className="pagination mb-0">
                                                    <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                                                        <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage - 1)}>{t("Bakiyeler_Onceki")}</Link>
                                                    </li>
                                                    {totalPages > 0 && Array.from({ length: totalPages }, (_, pageIndex) => (
                                                        <li className={`page-item ${currentPage === pageIndex + 1 && 'active'}`} key={pageIndex}>
                                                            <Link className="page-link" to="#" onClick={() => handleChangePage(pageIndex + 1)}>{pageIndex + 1}</Link>
                                                        </li>
                                                    ))}
                                                    <li className={`page-item ${currentPage === totalPages && 'disabled'}`}>
                                                        <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage + 1)}>{t("Bakiyeler_Sonraki")}</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Bakiyeler;