import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { getUserDataFromAuth, getFirmBankAccountsByUserId } from "../../../slices/thunks";
import { Col, Card, CardBody, CardTitle, CardText, Container, CardHeader, Row } from 'reactstrap';
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import i18next from 'i18next';

const OnlineBakiyeChart = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [balanceData, setBalanceData] = useState({});
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [hasData, setHasData] = useState(true);
    const scrollContainerRef = useRef(null);
    const [initialRender, setInitialRender] = useState(true);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [currentCardIndex, setCurrentCardIndex] = useState(0);
    const [showScrollHint, setShowScrollHint] = useState(true);

    useEffect(() => {
        const companyData = JSON.parse(localStorage.getItem("companyData") || "{}");
        const darkModePreference = localStorage.getItem('darkMode') === 'true';
        setIsDarkMode(darkModePreference);

        async function fetchBalanceData() {
            try {
                const userData = await dispatch(getUserDataFromAuth());
                const response = await dispatch(getFirmBankAccountsByUserId(userData.id, true));
                const firmBankAccounts = response.filter(account => account.firmId === companyData.companyID);
                const groupedData = groupByCurrency(firmBankAccounts);

                setBalanceData(groupedData);
                setHasData(Object.keys(groupedData).length > 0);
            } catch (error) {
                setHasData(false);
            }
        }

        fetchBalanceData();

        const darkModeObserver = new MutationObserver(() => {
            setIsDarkMode(document.body.classList.contains('dark-mode'));
        });
        darkModeObserver.observe(document.body, { attributes: true, attributeFilter: ['class'] });

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            darkModeObserver.disconnect();
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const groupByCurrency = (data) => {
        const grouped = {};
        data.forEach(item => {
            const { currencyType, balance, blockedBalance, bankName } = item;
            if (!grouped[currencyType]) {
                grouped[currencyType] = {
                    totalBalance: 0,
                    totalBlockedBalance: 0,
                    banks: new Set(),
                    accounts: 0
                };
            }
            grouped[currencyType].totalBalance += balance;
            grouped[currencyType].totalBlockedBalance += blockedBalance;
            grouped[currencyType].banks.add(bankName);
            grouped[currencyType].accounts += 1;
        });
        return grouped;
    };

    const renderCard = (currencyType) => {
        const { totalBalance, totalBlockedBalance, banks, accounts } = balanceData[currencyType] || {};

        return (
            <>
                <CardTitle tag="h3" className={`fs-4 ${isDarkMode ? 'text-light' : 'text-primary'}`}>
                    {currencyType}
                </CardTitle>
                <CardBody className="d-flex flex-column justify-content-center">
                    <CardText className={`fs-5 mb-2 ${isDarkMode ? 'text-light' : ''}`}>
                        <strong>{t("Dashboard_OnlineBakiyeChart_Balance")}</strong>
                        {initialRender ? (
                            <CountUp
                                start={0}
                                end={totalBalance}
                                decimals={2}
                                separator=","
                                duration={1}
                                formattingFn={(value) => value.toLocaleString()}
                            />
                        ) : (
                            totalBalance.toLocaleString()
                        )}
                    </CardText>
                    <CardText className={`fs-5 ${isDarkMode ? 'text-light' : ''}`}>
                        <strong>{t("Dashboard_OnlineBakiyeChart_BlockedBalance")}</strong> {totalBlockedBalance ? totalBlockedBalance.toLocaleString() : '0'}
                    </CardText>
                    <CardText className={`fs-6 ${isDarkMode ? 'text-light' : ''}`}>
                        <strong> {t("Dashboard_OnlineBakiyeChart_BankCount")}</strong> {banks ? banks.size : 0}
                    </CardText>
                    <CardText className={`fs-6 ${isDarkMode ? 'text-light' : ''}`}>
                        <strong>{t("Dashboard_OnlineBakiyeChart_AccountCount")}</strong> {accounts || 0}
                    </CardText>
                </CardBody>
            </>
        );
    };

    const scroll = (direction) => {
        const container = scrollContainerRef.current;
        if (container) {
            const scrollAmount = direction === 'left' ? -300 : 300;
            container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
        }
    };

    const calculateCardWidth = () => {
        const maxWidth = 300;
        const minWidth = 250;
        const padding = 30;
        const availableWidth = windowWidth - padding;

        if (windowWidth < 768) {
            return windowWidth - 150;
        } else if (availableWidth >= maxWidth) {
            return maxWidth;
        } else if (availableWidth <= minWidth) {
            return minWidth;
        } else {
            return availableWidth;
        }
    };

    const cardWidth = calculateCardWidth();

    const showScrollButtons = windowWidth >= 768 && Object.keys(balanceData).length > 3;

    return (
        <Card style={{ height: 420 }}>
            <CardHeader className="d-flex align-items-center p-0 mx-3 py-2" style={{ minHeight: 50 }}>
                <Col xs={12}>
                    <h6 className="card-title mb-0 text-capitalize" style={{ color: "#7ba6cb" }}>
                        {t("Dashboard_OnlineBakiyeChartTitle")}
                    </h6>
                </Col>
            </CardHeader>
            <CardBody>
                <Container fluid>
                    <Row className="justify-content-center align-items-center h-100 position-relative">
                        {hasData ? (
                            <Col xs={12} className="d-flex justify-content-center align-items-center position-relative">
                                {showScrollButtons && (
                                    <div
                                        className="position-absolute d-flex justify-content-center align-items-center"
                                        style={{
                                            left: 0,
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            zIndex: 1,
                                            cursor: 'pointer',
                                            width: 30,
                                            height: 60,
                                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                            borderTopRightRadius: 15,
                                            borderBottomRightRadius: 15
                                        }}
                                        onClick={() => scroll('left')}
                                    >
                                        <span style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>&#8249;</span>
                                    </div>
                                )}
                                <div
                                    ref={scrollContainerRef}
                                    className="d-flex overflow-auto w-100"
                                    style={{
                                        scrollbarWidth: 'none',
                                        msOverflowStyle: 'none',
                                        paddingLeft: '15px',
                                        paddingRight: '15px',
                                        flexWrap: 'nowrap',
                                        justifyContent: Object.keys(balanceData).length > 3 ? 'flex-start' : 'center'
                                    }}
                                >
                                    {Object.keys(balanceData).map((currencyType, index) => (
                                        <div key={currencyType} className="mx-2" style={{ minWidth: cardWidth, flex: '0 0 auto' }}>
                                            <Card
                                                body
                                                className={`text-center rounded shadow h-100 d-flex flex-column justify-content-center p-3 card-hover ${isDarkMode ? 'text-light' : ''}`}
                                                style={{ border: isDarkMode ? '2px solid #a0c0ff' : '1px solid #a0c0ff', width: cardWidth }}
                                            >
                                                {renderCard(currencyType === "TANIMSIZ" && i18next.language === "en" ? t("Dashboard_OnlineBakiyeChart_Undefined") : currencyType)}
                                            </Card>
                                        </div>
                                    ))}
                                </div>
                                {showScrollButtons && (
                                    <div
                                        className="position-absolute d-flex justify-content-center align-items-center"
                                        style={{
                                            right: 0,
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            zIndex: 1,
                                            cursor: 'pointer',
                                            width: 30,
                                            height: 60,
                                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                            borderTopLeftRadius: 15,
                                            borderBottomLeftRadius: 15
                                        }}
                                        onClick={() => scroll('right')}
                                    >
                                        <span style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>&#8250;</span>
                                    </div>
                                )}
                            </Col>
                        ) : (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '320px' }}>
                                <h5>{t("Dashboard_NoData")}</h5>
                            </div>
                        )}
                    </Row>
                    {window.outerWidth < 768 && Object.keys(balanceData).length > 1 && (
                        <Row>
                            <Col xs={12}>
                                <div
                                    className="position-absolute"
                                    style={{
                                        bottom: -45,
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                        color: 'white',
                                        padding: '8px 15px',
                                        borderRadius: '20px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
                                        transition: 'all 0.3s ease',
                                        zIndex: 10
                                    }}
                                >
                                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '5px' }}>
                                        <path d="M15 18L9 12L15 6" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <span style={{ margin: '0 5px', color: 'white', fontSize: '14px' }}>Kaydır</span>
                                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 18L15 12L9 6" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </Col>
                        </Row>
                    )}
                </Container>
            </CardBody>
        </Card>
    );
};

export default OnlineBakiyeChart;
