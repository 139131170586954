import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row, Input, Label } from 'reactstrap';
import GunSonuBakiyeChart from "./Charts/GunSonuBakiyeChart";
import TahsilatRaporuChart from "./Charts/TahsilatRaporuChart";
import OnlineBakiyeChart from "./Charts/OnlineBakiyeChart";
import IslemTipineGöreChart from './Charts/IslemTipineGoreChart';
import * as url from '../../helpers/url_helper';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const DashboardScreen = () => {
    const { t } = useTranslation();
    document.title = "Ana Sayfa | Tanasoft";

    const [totalBalance, setTotalBalance] = useState(0);
    const [bankCount, setBankCount] = useState(0);
    const [branchCount, setBranchCount] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const companyData = JSON.parse(localStorage.getItem("companyData") || "{}");

        async function fetchBalanceData() {
            try {
                const todayDate = (date) => {
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0');
                    const day = String(date.getDate()).padStart(2, '0');
                    return `${year}-${month}-${day}`;
                };

                const response = await axios.post(url.GET_BANK_TRANSACTIONS, {
                    "firms": [companyData.companyID],
                    "banks": [],
                    "bankBranches": [],
                    "firmBankAccounts": [],
                    "dynamicQuery": {
                        "filter": {
                            "field": "date",
                            "operator": "LessThanOrEqual",
                            "value": todayDate(new Date()),
                            "logic": "and",
                            "filters": [
                                {
                                    "field": "balance",
                                    "operator": "GreaterThan",
                                    "value": "0"
                                }
                            ]
                        },
                        "sort": [
                            {
                                "field": "date",
                                "dir": "Descending"
                            }
                        ]
                    }
                });

                const responseData = response.data.items;

                let uniqueBanks = new Set(responseData.map(item => item.bankName));
                let uniqueBranches = new Set(responseData.map(item => item.branchName));

                setBankCount(uniqueBanks.size);
                setBranchCount(uniqueBranches.size);

                const latestBalances = responseData.reduce((acc, item) => {
                    const bankName = item.bankName;
                    const existing = acc[bankName];

                    if (!existing || new Date(item.date) > new Date(existing.date)) {
                        acc[bankName] = item;
                    }

                    return acc;
                }, {});

                const total = Object.values(latestBalances).reduce((sum, item) => sum + parseFloat(item.balance), 0);

                setTotalBalance(total);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }

        fetchBalanceData();
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <div className="h-100">
                                <Col xs={12}>
                                    <Row>
                                        <Col xs={12} >
                                            <OnlineBakiyeChart></OnlineBakiyeChart>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} lg={6}>
                                            <Card style={{ height: 630 }}>
                                                <CardHeader className="d-flex align-items-center p-0 mx-3 py-2" style={{ minHeight: 50 }}>
                                                    <Col xs={12}>
                                                        <h6 className="card-title mb-0 text-capitalize" style={{ color: "#7ba6cb" }}>
                                                            {t("Dashboard_IslemTipineGoreHareketler_Gelen")}
                                                        </h6>
                                                    </Col>

                                                </CardHeader>
                                                <CardBody>
                                                    <div style={{ height: '500px', width: '100%' }}>
                                                        <IslemTipineGöreChart />
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>

                                        <Col xs={12} lg={6}>
                                            <Card style={{ height: 630 }}>
                                                <CardHeader className="d-flex align-items-center p-0 mx-3 py-2" style={{ minHeight: 50 }}>
                                                    <Col xs={7}>
                                                        <h6 className="card-title mb-0 text-capitalize" style={{ color: "#7ba6cb" }}>
                                                            {t("Dashboard_GunSonuBakiye")}
                                                        </h6>
                                                    </Col>
                                                </CardHeader>
                                                <div className="card-body">
                                                    <GunSonuBakiyeChart dataColors='["--vz-success"]' />
                                                </div>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col xs={12}>
                                    <Row>
                                        <Col xs={12} >
                                            <Card style={{ height: 670 }}>
                                                <CardHeader className="d-flex align-items-center p-0 mx-3 py-2" style={{ minHeight: 50 }}>
                                                    <Col xs={6}>
                                                        <h6 className="card-title mb-0 text-capitalize" style={{ color: "#7ba6cb" }}>
                                                            {t("Dashboard_TahsilatRaporu")}
                                                        </h6>
                                                    </Col>
                                                </CardHeader>
                                                <div className="card-body">
                                                    <TahsilatRaporuChart dataColors='["--vz-success"]' />
                                                </div>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div >
        </React.Fragment>
    );
};

export default DashboardScreen;