import axios from "axios";
import { getDecryptedData } from "./utils";

const userData = getDecryptedData("userData");
const accessToken = userData ? userData.accessToken["token"] : null;
const refreshToken = userData ? userData.refreshToken["token"] : null;

axios.defaults.baseURL = process.env.REACT_APP_API;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.common["Accept-Language"] = localStorage.getItem("I18N_LANGUAGE");
if (accessToken)
    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

const setAuthorization = (token) => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

const getLoggedinUser = () => {
    const userData = getDecryptedData("userData");
    
    if (!userData) {
        return null;
    } else {
        return userData;
    }
};

export { setAuthorization, getLoggedinUser };