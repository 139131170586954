import React, { useState, useEffect } from 'react';
import { Card, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Spinner } from 'reactstrap';
import { useDispatch } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import withRouter from "../../Components/Common/withRouter";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { loginUser, addUserToFirmByInvite, getUserDataFromAuth, updateUser } from "../../slices/thunks";
import SelectCompanyPopup from "../../Components/Common/SelectCompanyPopup";
import AuthCodePopup from "../../Components/Common/AuthCodePopup";
import EmailAuthCodePopup from "../../Components/Common/EmailAuthCodePopup";
import SetUserDetailsPopup from "../../Components/Common/SetUserDetailsPopup";
import { getDecryptedData, sendNotify, useHandleError } from '../../helpers/utils';

const Login = () => {
    const userData = getDecryptedData("userData");
    const companyData = JSON.parse(localStorage.getItem("companyData"));
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const handleError = useHandleError();
    const token = searchParams.get('Token');
    const email = searchParams.get('Email');
    const userWithEmail = searchParams.get('UserWithEmail');
    const [loading, setLoading] = useState(false);
    const [openCompanyPopup, setCompanyPopup] = useState(false);
    const [passwordShow, setPasswordShow] = useState(false);
    const [openAuthCodePopup, setAuthCodePopup] = useState(false);
    const [userAuthCode, setUserAuthCode] = useState(null);
    const [openEmailAuthCodePopup, setEmailAuthCodePopup] = useState(false);
    const [userEmailAuthCode, setUserEmailAuthCode] = useState(null);
    const [userDetailsPopup, setUserDetailsPopup] = useState(false);
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: userWithEmail ? email : '',
            password: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("E-Posta adresinizi girin"),
            password: Yup.string().required("Şifrenizi girin"),
        }),
        onSubmit: (values) => {
            handleLogin(values);
        }
    });
    document.title = "Giriş Yap | Tanasoft";

    useEffect(() => {
        if (userData) {
            if (companyData) {
                navigate('/dashboard');
            } else {
                setCompanyPopup(true);
            }
        }
    }, [userData, companyData, navigate]);

    const handleLogin = async (values) => {
        try {
            setLoading(true);
            const response = await dispatch(loginUser(values));

            await handleAuthentication(response, values);
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const handleAuthentication = async (response, values) => {
        switch (response?.requiredAuthenticatorType) {
            case "Otp":
                setUserAuthCode(values);
                setAuthCodePopup(true);
                break;
            case "Email":
                setUserEmailAuthCode(values);
                setEmailAuthCodePopup(true);
                break;
            default:
                if (userWithEmail) {
                    handleFirmAssignment();
                }
                setCompanyPopup(true);
        }
    };

    const handleFirmAssignment = async () => {
        try {
            await dispatch(addUserToFirmByInvite(token));
            if (userWithEmail === "False") {
                setUserDetailsPopup(true);
            }
        } catch (err) {
            handleError(err);
        }
    };

    const handleCompanyPopupConfirm = () => {
        setCompanyPopup(false);
        navigate('/dashboard');
    };

    const handleAuthCodePopupConfirm = (authCode) => {
        if (userAuthCode && authCode) {
            setLoading(true);
            dispatch(loginUser(userAuthCode, authCode)).then(() => {
                setLoading(false);
                setAuthCodePopup(false);
                if (userWithEmail) {
                    handleFirmAssignment();
                }
                setCompanyPopup(true);
            }).catch((err) => {
                setLoading(false);
                handleError(err);
            });
        } else {
            sendNotify(t('Popup_AuthCodeCannotBeEmpty'), 'danger');
        }
    };

    const handleEmailAuthCodePopupConfirm = (authCode) => {
        if (userEmailAuthCode && authCode) {
            setLoading(true);
            dispatch(loginUser(userEmailAuthCode, authCode)).then(() => {
                setLoading(false);
                setEmailAuthCodePopup(false);
                if (userWithEmail) {
                    handleFirmAssignment();
                }
                setCompanyPopup(true);
            }).catch((err) => {
                setLoading(false);
                handleError(err);
            });
        } else {
            sendNotify(t('Popup_AuthCodeCannotBeEmpty'), 'danger');
        }
    };

    const handleUserDetailsPopupConfirm = (firstName, lastName) => {
        if (firstName && lastName) {
            dispatch(getUserDataFromAuth()).then(response => {
                const formData = {
                    id: response.id,
                    firstName: firstName,
                    lastName: lastName,
                    email: response.email,
                    password: ''
                }
                setLoading(true);
                dispatch(updateUser(formData)).then(response => {
                    setLoading(false);
                    navigate("/dashboard");
                }).catch(err => {
                    handleError(err);
                })
            }).catch(err => {
                handleError(err);
                setLoading(false);
            })
        }
    };

    return (
        <React.Fragment>
            <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
                <div className="bg-overlay"></div>
                <div className="auth-page-content overflow-hidden pt-lg-5">
                    <Container className="d-flex justify-content-center align-items-center">
                        <Row className="w-100 justify-content-center">
                            <Col lg={6}>
                                <Card className="overflow-hidden">
                                    <div className="p-lg-5 p-4">
                                        <div>
                                            <h5 className="text-primary">Hoş Geldiniz</h5>
                                            <p className="text-muted">Tanasoft'a devam etmek için oturum açın.</p>
                                        </div>

                                        <div className="mt-4">
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}
                                                action="#">

                                                <div className="mb-3">
                                                    <Label htmlFor="username" className="form-label">E-Posta <span className="text-danger">*</span></Label>
                                                    <Input
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="E-posta adresinizi girin"
                                                        type="email"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.email || ""}
                                                        disabled={loading}
                                                    />

                                                    {validation.touched.email && validation.errors.email ? (
                                                        <div className="text-danger mt-2">{validation.errors.email}</div>
                                                    ) : null}
                                                </div>

                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="password-input">Şifre <span className="text-danger">*</span></Label>
                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                        <Input
                                                            name="password"
                                                            value={validation.values.password || ""}
                                                            type={passwordShow ? "text" : "password"}
                                                            className="form-control pe-5"
                                                            placeholder="Şifrenizi girin"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            disabled={loading}
                                                        />

                                                        {validation.touched.password && validation.errors.password ? (
                                                            <div className="text-danger mt-2">{validation.errors.password}</div>
                                                        ) : null}

                                                        <Button color="link" onClick={() => setPasswordShow(!passwordShow)} className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="password-addon">
                                                            <i className="ri-eye-fill align-middle"></i>
                                                        </Button>
                                                    </div>
                                                </div>

                                                <div className="mt-4">
                                                    <Button color="success" className="btn btn-success w-100" type="submit" disabled={loading || !validation.values.email || !validation.values.password}>
                                                        {loading ? <Spinner size="sm" className='me-2'> Yükleniyor... </Spinner> : null}
                                                        Giriş Yap
                                                    </Button>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                    {/* <Row className="g-0">
                                        {/* <Col lg={6}>
                                            <AuthSlider />
                                        </Col> 
                                    </Row> */}
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <footer className="footer">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center">
                                    <p className="mb-0">&copy; {new Date().getFullYear()} Tanasoft - Tüm Hakları Saklıdır.</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </footer>
            </div>

            <SelectCompanyPopup isOpen={openCompanyPopup} toggle={() => setCompanyPopup(!openCompanyPopup)} onConfirm={handleCompanyPopupConfirm} source={userWithEmail ? "login-with-email" : "login"} />
            <AuthCodePopup isOpen={openAuthCodePopup} onConfirm={handleAuthCodePopupConfirm} loading={loading} />
            <EmailAuthCodePopup isOpen={openEmailAuthCodePopup} onConfirm={handleEmailAuthCodePopupConfirm} loading={loading} />
            <SetUserDetailsPopup isOpen={userDetailsPopup} onConfirm={handleUserDetailsPopupConfirm} loading={loading} />
        </React.Fragment>
    );
};

export default withRouter(Login);