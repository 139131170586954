import CryptoJS from "crypto-js";
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";

export const setEncryptedData = (key, data) => {
    try {
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_SECRET_KEY).toString();
        localStorage.setItem(key, encryptedData);
    } catch (error) {
        console.error("Error encrypting data", error);
    }
};

export const getDecryptedData = (key) => {
    try {
        const encryptedData = localStorage.getItem(key);
        if (!encryptedData) {
            return null;
        }
        const bytes = CryptoJS.AES.decrypt(encryptedData, process.env.REACT_APP_SECRET_KEY);
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return JSON.parse(decryptedData);
    } catch (error) {
        console.error("Error decrypting data", error);
        return null;
    }
};

export const sendNotify = (message, type) => toast(message, {
    position: "top-right",
    hideProgressBar: true,
    className: `bg-${type} text-white`,
    autoClose: 5000
});

export const useHandleError = () => {
    const { t } = useTranslation();

    const handleError = (err) => {
        const response = err.response.data;
        const errors = response.Errors || [];
        let errorMessage = t('Notification_ErrorMessage');

        if (errors.length > 0) {
            errors.forEach(error => {
                error.Errors.forEach(errorMessage => {
                    sendNotify(`${errorMessage}`, 'danger');
                });
            });
        } else {
            errorMessage = response.detail;
            sendNotify(errorMessage, 'danger');
        }
    };

    return handleError;
};

export const calculateExpirationDays = (expirationDate) => {
    const now = new Date();
    const expiry = new Date(expirationDate);
    const timeDiff = expiry.getTime() - now.getTime();
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return daysDiff > 0 ? daysDiff : 0;
};