import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getUserDataFromAuth, getFirmsByUserId } from "../slices/thunks";
import { useHandleError } from "../helpers/utils";

const Navdata = () => {
    const { t } = useTranslation();
    const history = useNavigate();
    const dispatch = useDispatch();
    const handleError = useHandleError();
    const [isDashboard, setIsDashboard] = useState(false);
    const [isOnlineBanka, setIsOnlineBanka] = useState(false);
    const [isHesapIslemleri, setIsHesapIslemleri] = useState(false);
    const [isBakiye, setIsBakiye] = useState(false);
    const [isRaporlar, setIsRaporlar] = useState(false);
    const [isAdminSettings, setIsAdminSettings] = useState(false);
    const [iscurrentState, setIscurrentState] = useState("Dashboard");
    const [isFirmAdmin, setIsFirmAdmin] = useState(false);

    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove("twocolumn-panel");

        if (iscurrentState !== "Dashboard")
            setIsDashboard(false);

        if (iscurrentState !== "OnlineBanka")
            setIsOnlineBanka(false);

        if (iscurrentState !== "AdminSettings")
            setIsAdminSettings(false);
    }, [
        history,
        iscurrentState,
        isDashboard,
        isOnlineBanka,
        isAdminSettings
    ]);

    const fetchUserData = async () => {
        const companyData = JSON.parse(localStorage.getItem("companyData") || "{}");

        dispatch(getUserDataFromAuth()).then((authResponse) => {
            const userId = authResponse.id;
            dispatch(getFirmsByUserId(userId)).then((response) => {
                const firm = response.find(firm => firm.firmId === companyData.companyID);
                if (firm) {
                    if (firm.firmAdmin) {
                        setIsFirmAdmin(true);
                    } else {
                        setIsFirmAdmin(false);
                    }
                }
            }).catch((err) => {
                handleError(err);
            });
        }).catch((err) => {
            handleError(err);
        });
    };

    useEffect(() => {
        fetchUserData();
    }, []);

    const menuItems = [
        {
            label: "Menu",
            isHeader: true,
        },
        {
            id: "dashboard",
            label: "Ana Sayfa",
            icon: "ri-dashboard-2-line",
            link: "/dashboard",
        },
        {
            id: "onlineBanka",
            label: "Online Banka",
            icon: "ri-apps-2-line",
            link: "/online-banka",
            click: function (e) {
                e.preventDefault();
                setIsOnlineBanka(!isOnlineBanka);
                setIscurrentState("OnlineBanka");
                updateIconSidebar(e);
                history("/online-banka");
            },
            stateVariables: isOnlineBanka,
            subItems: [
                {
                    id: "hesapIslemleri",
                    label: "Hesap İşlemleri",
                    link: "/#",
                    isChildItem: true,
                    click: function (e) {
                        e.preventDefault();
                        setIsHesapIslemleri(!isHesapIslemleri);
                    },
                    parentId: "onlineBanka",
                    stateVariables: isHesapIslemleri,
                    childItems: [
                        { id: 1, label: "Hesap Hareketleri", link: "/hesap-hareketleri" },
                    ],
                },
                {
                    id: "bakiye",
                    label: "Bakiye",
                    link: "/#",
                    isChildItem: true,
                    click: function (e) {
                        e.preventDefault();
                        setIsBakiye(!isBakiye);
                    },
                    parentId: "onlineBanka",
                    stateVariables: isBakiye,
                    childItems: [
                        { id: 1, label: t("LayoutMenuData_Bakiyeler"), link: "/bakiyeler" },
                        { id: 2, label: t("LayoutMenuData_BankaBakiyeler"), link: "/bankabakiyeler" },
                    ],
                },
                {
                    id: "digerRaporlar",
                    label: t("OnlineBanka_Raporlar"),
                    link: "/#",
                    isChildItem: true,
                    click: function (e) {
                        e.preventDefault();
                        setIsRaporlar(!isRaporlar);
                    },
                    parentId: "onlineBanka",
                    stateVariables: isRaporlar,
                    childItems: [
                        { id: 1, label: "Hesap Hareket Raporu", link: "/hesap-hareket-raporu" },
                    ],
                }
            ],
        },
        isFirmAdmin && {
            id: "adminSettings",
            label: t('AdminSettings'),
            icon: "ri-pages-line",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsAdminSettings(!isAdminSettings);
                setIscurrentState("AdminSettings");
                updateIconSidebar(e);
            },
            stateVariables: isAdminSettings,
            subItems: [
                { id: 1, label: t('AdminSettings_Users'), link: "/users" },
                { id: 2, label: t('AdminSettings_InviteUser'), link: "/invite-user" },
                { id: 3, label: t('AdminSettings_EditFirm'), link: "/edit-firm-details" },
            ],
        },
    ].filter(Boolean);

    return <React.Fragment>{menuItems}</React.Fragment>;
};

export default Navdata;